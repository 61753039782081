import convert from 'convert';
import DOMPurify from 'dompurify';
import { cloneDeep, debounce, isEmpty, isEqual, isString, orderBy, xorWith } from 'lodash-es';
import { customAlphabet } from 'nanoid';
import Cookies from 'universal-cookie';
import { useToast } from 'vue-toastification';
import { useAuthStore } from '~/auth/stores/auth.store';
import HawkToast from '~/common/components/organisms/hawk-toast.vue';
import { useCommonStore } from '~/common/stores/common.store';
import { blacklist_extensions } from '~/common/utils/constants';
import { currencies } from '~/common/utils/constants.ts';
import { version } from '../../../package.json';

const toastification = useToast();
const cookies = new Cookies();
/* -------------------------------------------------------------------------- */
/*                                Cookies utils                               */
/* -------------------------------------------------------------------------- */
export const getCookie = key => cookies.get(key);

export function accessTokenCookie(type = 'get', token = null) {
  const key = getHostName() === 'localhost' ? 'localhost_access_token' : `${import.meta.env.VITE_APP_ENV}_access_token`;
  if (type === 'get')
    return getCookie(key);
  else if (type === 'set')
    setCookie(key, token);
  else if (type === 'remove')
    removeCookie(key);
}

export function currentOrganizationCookie(type = 'get', token = null) {
  const key = getHostName() === 'localhost' ? 'localhost_current_organization' : `${import.meta.env.VITE_APP_ENV}_current_organization`;
  if (type === 'get') {
    const org_cookie = getCookie(key);
    return (org_cookie === 'undefined' || org_cookie === 'null' || !org_cookie) ? null : org_cookie;
  }
  else if (type === 'set') {
    setCookie(key, token);
  }
  else if (type === 'remove') {
    removeCookie(key);
  }
}

export function removeCookie(key) {
  return cookies.remove(key, {
    path: '/',
    domain: getHostName(),
    secure: !getHostName().includes('localhost'),
  });
}

const a_year_from_now = new Date();
a_year_from_now.setFullYear(a_year_from_now.getFullYear() + 1);
export function setCookie(key, value, options = {
  path: '/',
  expires: '',
  domain: getHostName(),
  secure: !getHostName().includes('localhost'),
}) {
  const date_object = new Date();
  date_object.setFullYear(date_object.getFullYear() + 1);
  options.expires = date_object;
  cookies.set(key, value, options);
}

/* -------------------------------------------------------------------------- */
/*                                 Toast utils                                */
/* -------------------------------------------------------------------------- */

export function $toast({ timeout = 3000, position = 'top-right', listeners = {}, ...hawk_toast_props }) {
  const content = {
    component: HawkToast,
    props: hawk_toast_props,
    listeners,
  };

  return toastification?.[hawk_toast_props?.type]?.(content, {
    position,
    timeout,
    toastClassName: 'hawk-toast',
    ...(hawk_toast_props?.config || {}),
  });
}

/* -------------------------------------------------------------------------- */
/*                               Hostname - util                              */
/* -------------------------------------------------------------------------- */
export function getHostName() {
  const hostname = window.location.hostname;
  if (hostname.includes('localhost'))
    return 'localhost';
  if (!hostname.includes('taskmapper.com'))
    return '';
  else
    return hostname.substring(hostname.indexOf('.'));
}

/* -------------------------------------------------------------------------- */
/*                            Persisted store utils                           */
/* -------------------------------------------------------------------------- */

export function clearPersistedStore() {
  function getPersistedStoreKey(key) {
    return getHostName() === 'localhost' ? `localhost_persisted_${key}_store` : `${import.meta.env.VITE_APP_ENV}_persisted_${key}_store`;
  }
  localStorage.removeItem(getPersistedStoreKey('common'));
  localStorage.removeItem(getPersistedStoreKey('tags'));
}

export function purgeCommonStore() {
  const common_store = useCommonStore();
  common_store.$reset();
  common_store.$persist();
  localStorage.clear();
}

/* -------------------------------------------------------------------------- */
/*                                 Auth utils                                 */
/* -------------------------------------------------------------------------- */

export function clearAuthentication() {
  const auth_store = useAuthStore();
  if (auth_store.is_one_signal_initialized) {
    window.OneSignal.push(() => {
      window.OneSignal.removeExternalUserId();
    });
  }
  purgeCommonStore();
  accessTokenCookie('remove');
}

/* -------------------------------------------------------------------------- */
/*                          Miscellaneous/Helper utils                         */
/* -------------------------------------------------------------------------- */
export function sortObjectByKey(obj) {
  return Object.keys(obj || {})
    .sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }))
    .reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});
}

export async function fetchUrl(url, use_auth_headers = false) {
  try {
    if (use_auth_headers) {
      return await fetch(url, {
        headers: { Authorization: `jwt ${getCookie('access_token')}` },
      });
    }
    else {
      return await fetch(url);
    }
  }
  catch (error) {
    logger.error(error);
  }
  return null;
}

export function searchData(data = [], keyword = '', properties = ['name']) {
  if (!keyword)
    return data;
  if (isString(properties))
    properties = [properties];

  const search_keys = keyword.toLowerCase();
  return data.filter((item) => {
    return properties.some((property) => {
      return item[property]?.toLowerCase()?.includes(search_keys);
    });
  });
}

export function sortData(data = [], property = '', order = ['desc'], case_in_sensitive = false) {
  if (!property)
    return data;
  return orderBy(data, [item => case_in_sensitive ? item[property]?.toLowerCase() : item[property]], order);
}

export function treeToList(data) {
  const _data = cloneDeep(data);
  function flatten(xs) {
    return xs.reduce((acc, x) => {
      acc = acc.concat(x);
      const items = x.items || x.children || x.folders;
      if (items) {
        acc = acc.concat(flatten(items));
        x.items = [];
        x.children = [];
        x.folders = [];
      }
      return acc;
    }, []);
  }

  return flatten(_data);
}

export function listToTree(list, dangling = false) {
  const map = {};
  let node;
  const roots = [];
  let i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].uid] = i; // initialize the map
    list[i].children = []; // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (
      node.parent
      && list[map[node.parent]]
      && (dangling ? map[node.parent] : true)
    ) {
      // if you have dangling branches check that map[node.parentId] exists
      list[map[node.parent]].children.push(node);
    }

    else {
      roots.push(node);
    }
  }
  return roots;
}

export function compareObjects(obj1, obj2) {
  // Recursively compare each property of the two objects
  if (typeof obj1 !== typeof obj2)
    return false;

  if (typeof obj1 !== 'object' || obj1 === null)
    return obj1 === obj2;

  if (Object.keys(obj1).length !== Object.keys(obj2).length)
    return false;

  for (const key in obj1) {
    if (!compareObjects(obj1[key], obj2[key]))
      return false;
  }

  return true;
}

export function parseStringifiedObjectsInArray(array) {
  // This will change the original array you are giving by object reference
  function parseObjectStrings(object) {
    // Iterate over all keys in the object
    for (const key in object) {
      const value = object[key];

      if (typeof value === 'object') {
        // Recursively parse nested objects
        parseObjectStrings(value);
      }
      else if (typeof value === 'string' && (value.startsWith('{') || value.startsWith('['))) {
        // Attempt to parse only if the string looks like a JSON object or array
        try {
          object[key] = JSON.parse(value);
        }
        catch (error) {
          logger.error(error);
        }
      }
    }

    return object;
  }

  // Iterate over all objects in the array
  for (const item of array) {
    if (typeof item === 'object') {
      parseObjectStrings(item);
    }
  }

  return array;
}

export function hexToRgb(hex) {
  if (!hex)
    return null;
  const bigint = Number.parseInt(hex.replace('#', ''), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return { r, g, b };
}

export function htmlToText(html) {
  const html_string_with_tags_removed = html
    .replace(/\n/g, '')
    .replace(/\t/g, '')
    .replace(/<\/td>/g, '\t')
    .replace(/<\/table>/g, '\n')
    .replace(/<\/tr>/g, '\n')
    .replace(/<\/p>/g, '\n')
    .replace(/<\/div>/g, '\n')
    .replace(/<\/h>/g, '\n')
    .replace(/<br>/g, '\n')
    .replace(/<br( )*\/>/g, '\n');
  const dom = new DOMParser().parseFromString(
    `<!doctype html><body>${html_string_with_tags_removed}`,
    'text/html',
  );
  return dom.body.textContent;
}

export function dataURLtoFile(dataurl, filename) {
  try {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[arr.length - 1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--)
      u8arr[n] = bstr.charCodeAt(n);

    return new File([u8arr], filename, { type: mime });
  }
  catch (error) {
    logger.error(error);
    return null;
  }
}

export function convertTableUnitsData(value, unit) {
  if (Number.isNaN(Number(value))) {
    return value;
  }
  else {
    try {
      const converted_data = convert(value, unit).to('best');
      if (converted_data.unit === unit) {
        return converted_data.quantity.toFixed(2);
      }
      else {
        let num_value = Number.parseFloat(converted_data.quantity);
        if (num_value !== Math.floor(num_value))
          num_value = num_value.toFixed(2);

        return `${num_value} ${converted_data.unit}`;
      }
    }
    catch (error) {
      logger.error(error);
      // unit likely not supported
      if (Number.isNaN(Number(value)) || Number.isInteger(value))
        return value;
      else
        return Number(value).toFixed(2);
    }
  }
}

export function handleAppUpdate(source = 'unknown', mandatory = false) {
  logger.info('common.utils.js::339\nhandleAppUpdate', source);

  const message = mandatory
    ? 'The latest version of the app is required to proceed with this action. Please reload.'
    : 'New version of app is available. Please reload.';
  // eslint-disable-next-line no-alert
  const reload = confirm(message);
  if (reload)
    window.location.reload();
}

export function csvInjectionProtector(str) {
  if (!str)
    return '';
  str = str
    .replaceAll('.exe', '')
    .replaceAll('powershell', '')
    .replaceAll('dll', '')
    .replaceAll('cmd', '');
  if (!['=', '+', '-', '@'].includes(str.charAt(0)))
    return str;
  return csvInjectionProtector(str.slice(1));
}

export function sanitizeObjectForCSVInjection(target) {
  const sanitized_target = cloneDeep(target);
  const fields_to_ignore = [
    'uid',
    'field_uid',
    'owner',
    'organization',
    'workflow_uid',
    'asset',
    'values',
  ];

  Object.keys(sanitized_target).forEach((key) => {
    if (
      typeof sanitized_target[key] === 'object'
      && sanitized_target[key] !== null
    ) {
      sanitized_target[key] = sanitizeObjectForCSVInjection(sanitized_target[key]);
    }

    else if (isString(sanitized_target[key])) {
      if (!fields_to_ignore.includes(key))
        sanitized_target[key] = csvInjectionProtector(sanitized_target[key]);
    }
  });

  return sanitized_target;
}

function getModule(route) {
  const path = route.path.split('/').filter(val => val.length);
  let name = '';
  if (path.length === 0)
    return 'Core';
  if (route.name.includes('fam') || route.name === 'form-approval')
    return 'Forms';
  name = route.params?.asset_id ? path[1] : path[0];
  const pathModuleNameMap = {
    'tasks': 'Tasks',
    'form': 'Forms',
    'forms': 'Forms',
    'terra-viewer': 'Terra',
    'therm': 'Therm',
    'dms': 'Files',
    'project-management': 'Project management',
    'system-model': 'System Model',
    'plans': 'Plan',
    'inventory': 'Inventory',
    'dashboard': 'Dashboard',
  };
  return pathModuleNameMap[name];
}

export function track_event(event_name, properties = {}) {
  const auth_store = useAuthStore();
  const common_store = useCommonStore();
  const route = common_store.$router.currentRoute.value;
  if (common_store.active_asset)
    properties.asset = common_store.active_asset.name;
  const default_segment_props = {
    organization: auth_store?.current_organization?.name ?? '',
    access_level: auth_store.get_access_level,
    session_id: sessionStorage.getItem('segment_session_id'),
    version,
    application: 'Desktop',
    module: getModule(route),
  };

  getHostName() !== 'localhost' && auth_store.check_split('debug_events') && logger.table({ event_name, ...default_segment_props, ...properties });
  if (window.analytics?.initialize) {
    window.analytics.track(event_name, {
      ...default_segment_props,
      ...properties,
    });
  }
}

export function asyncDebounce(func, wait) {
  const debounced = debounce(async (resolve, reject, bindSelf, args) => {
    try {
      const result = await func.bind(bindSelf)(...args);
      resolve(result);
    }
    catch (error) {
      reject(error);
    }
  }, wait);

  // This is the function that will be bound by the caller, so it must contain the `function` keyword.
  function returnFunc(...args) {
    return new Promise((resolve, reject) => {
      debounced(resolve, reject, this, args);
    });
  }

  return returnFunc;
}

export function getRandomKey() {
  const crypto = window.crypto || window.msCrypto;
  const unit_arr = new Uint32Array(1);
  return crypto.getRandomValues(unit_arr)[0];
}

export async function sleep(delay = 0) {
  return new Promise(resolve => setTimeout(resolve, delay));
}

export function sortRowsByColumn(row_a, row_b, column_id) {
  const a = row_a.getValue(column_id);
  const b = row_b.getValue(column_id);
  const alt = b > a ? 1 : 0;
  return a > b ? -1 : alt;
}

export function getPageNameByRouteName(route_name) {
  const route_name_to_page_name_map = {
    'asset-dashboard': 'Dashboard',
    'terra-viewer': 'Terra',
    'maps': 'Map',
    'therm': 'Therm',
    'therm-defects': 'Therm Defects List',
    'tasks': 'List',
    'task-templates': 'TemplateList',
    'files': 'Files',
    'files-documents-all-files': 'DMS',
    'schedule-details': 'Project Management',
    'sheet': 'Plan',
    'form-template-instances': 'TemplateList',
    'form-template-responses': 'Form Responses List',
    'form-instances': 'FormList',
    'form-overview-forms-list': 'FormList',
    'form-overview-submission-list': 'Submissions',
    'fam-overview': 'Dashboard',
    'fam-list': 'FormList',
    'fam-calendar': 'Calendar',
    'fam-kanban': 'Kanban',
    'fam-gallery': 'Gallery',
    'form-approval': 'ApprovalRedirect',
  };

  return route_name_to_page_name_map[route_name];
}

export function doesThisLookLikeAnEmail(email) {
  // This is a simple email validator for quick and dirty checks. Don't use it for super-serious validations.
  // It matches anystring@anystring.anystring
  const re = /\S[^\s@]*@\S+\.\S+/;
  return re.test(email);
}

export function checkUser(user) {
  return (((user?.first_name && user?.last_name)
    ? (`${user?.first_name} ${user?.last_name}`)
    : user?.email) || user?.username);
}

export function getUserFullName(user) {
  // for team
  if (user?.name)
    return user.name;

  // for loggedIn user
  else if (user?.firstname && !user?.lastname)
    return user.firstname;
  else if (user?.firstname && user?.lastname)
    return `${user.firstname} ${user.lastname}`;

  // for other users
  else if (user?.first_name && !user?.last_name)
    return user.first_name;
  else if (user?.first_name && user?.last_name)
    return `${user.first_name} ${user.last_name}`;

  // fallback
  return user?.email || '';
}

export function getFileExtension(filename) {
  const ext = filename?.split('.')?.pop();
  if (ext === filename)
    return '';
  return ext;
}

export const nanoid = customAlphabet(
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890',
  11,
);

export function removeEmptyKeys(obj) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === undefined || obj[key] === '')
      delete obj[key];
  });
  return obj;
}

export function changeIconDimensions(icon, width = 12, height = 12) {
  if (!icon?.replace)
    return icon;
  if (!width)
    return icon.replace(/\sheight="\d+"/g, ` height="${height}"`);
  if (!height)
    return icon.replace(/\swidth="\d+"/g, ` width="${width}"`);
  return icon
    .replace(/\swidth="\d+"/g, ` width="${width}"`)
    .replace(/\sheight="\d+"/g, ` height="${height}"`);
}

export function stringToNumber(string) {
  const color_set = ['#FE8A52', '#43C678', '#FE6363', '#5B607E', '#07A192'];
  if (!string)
    return;
  let total = 0;
  for (const str of string)
    total += Number.parseInt(str.charCodeAt(0));

  return color_set[total % color_set.length];
}

export function snakeCaseToWord(inputString) {
  const words = inputString.split('_');
  const formattedString = words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

  return formattedString;
}

export function getTextWidth(text, font) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font;
  return Math.ceil(context.measureText(text).width);
}

export function useResourceHelper() {
  const getResourceName = (activity, fallback = '-') => {
    let resource_key = '';
    switch (activity.resource_name) {
      case 'FORM_TEMPLATE':
        resource_key = 'template';
        break;
      case 'ticket':
      case 'TICKET':
        resource_key = 'task';
        break;
      case 'TRANSMITTAL_DOCUMENT':
        resource_key = 'transmittal';
        break;
      default:
        resource_key = activity.resource_name.toLowerCase();
    }

    if (resource_key === 'user') {
      const { get_user_or_team_name } = useCommonStore();
      return get_user_or_team_name(activity.meta[resource_key]) || fallback;
    }

    return activity.meta?.[resource_key]?.name || fallback;
  };

  const getResourceType = (activity) => {
    let resource_name = activity.resource_name;
    if (resource_name === 'TICKET' || resource_name === 'ticket')
      resource_name = 'TASK';
    return snakeCaseToWord(resource_name);
  };

  return { getResourceName, getResourceType };
}

export function isFileExtensionAllowed(filename, suppress_toast = false) {
  if (typeof filename !== 'string' || !filename.length)
    return true;

  const filename_split = filename.trim().split('.');
  if (filename_split.length > 1 && blacklist_extensions.includes(filename_split.pop())) {
    !suppress_toast && $toast({
      title: 'Unsupported file type',
      text: `${filename} has an unsupported extension.`,
      type: 'error',
      timeout: 4000,
    });
    return false;
  }
  return true;
}

export function hexToRgbA(hex, opacity = '0.1') {
  let c;
  // eslint-disable-next-line regexp/no-unused-capturing-group
  if (/^#([A-F0-9]{3}){1,2}$/i.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3)
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];

    c = `0x${c.join('')}`;
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')}, ${opacity})`;
  }
  throw new Error('Bad Hex');
}

export function sanitizeAoA(aoa) {
  for (const arr of aoa) {
    for (let j = 0; j < arr.length; j++) {
      if (typeof arr[j] === 'string' || arr[j] instanceof String)
        arr[j] = csvInjectionProtector(arr[j]);
    }
  }
  return aoa;
}

export function getCurrencyDetails(currency_code) {
  if (currency_code)
    return currencies[currency_code];
  return {};
}

export function renderAsTextarea(text) {
  // USAGE: <div v-html="renderAsTextarea('string')" />
  if (!text)
    return '';
  return (DOMPurify.sanitize(text, { ALLOWED_TAGS: [] })).split(' ').join('&nbsp;').split('\n').join('<br/>');
}

export function waitForElement(id, timeout = 2000) {
  let time = 0;
  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      const element = document.getElementById(id);
      if (element) {
        clearInterval(interval);
        resolve(element);
      }
      else {
        if (time >= timeout) {
          clearInterval(interval);
          reject(new Error('Timed out'));
        }
        time += 100;
      }
    }, 100);
  });
}

export function highlightElement(el, additional_classes = [], scroll_options = { behavior: 'smooth', block: 'center', inline: 'nearest' }) {
  if (el) {
    el.scrollIntoView(scroll_options);
    setTimeout(() => {
      el.classList.add('bg-warning-100', 'transition-colors', 'duration-1000', 'ease-in-out', 'rounded-lg', ...additional_classes);
    }, 500);
    setTimeout(() => {
      el.classList.add('bg-white');
    }, 1500);
    setTimeout(() => {
      el.classList.remove('bg-warning-100', 'transition-colors', 'duration-1000', 'ease-in-out', 'bg-white', 'rounded-lg', ...additional_classes);
    }, 2500);
  }
}

export function trainCase(str, delimiter = '-') {
  if (!str)
    return '';
  // Replace all non-alphanumeric characters (excluding Unicode letters and digits) with a space
  const cleaned_str = str.replace(/[^\p{L}\p{N}]+/gu, ' ');
  return cleaned_str
    .trim()
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(delimiter);
}

export function checkSwipeNavigation() {
  // countdown in ms before resetting the boolean.
  const iTime = 1000;
  let oTimeout;
  let swipe_navigation = false;
  let pop_state_detected = false;
  window.addEventListener('mousewheel', (e) => {
    if (e.wheelDeltaY === 0) {
      // there is an horizontal scroll
      swipe_navigation = true;
      clearTimeout(oTimeout);
      oTimeout = setTimeout(() => {
        swipe_navigation = false;
      }, iTime);
    }
  });
  window.addEventListener('popstate', () => {
    pop_state_detected = true;
  });

  function alertSwipeNavigation() {
    const _swipe_navigation = swipe_navigation;
    const _popStateDetected = pop_state_detected;
    swipe_navigation = false;
    pop_state_detected = false;
    if (_swipe_navigation && _popStateDetected) {
      const message = 'You have unsaved changes. Do you want to navigate away?';
      // eslint-disable-next-line no-alert
      const reload = confirm(message);
      if (!reload)
        return false;
    }
    return true;
  }
  return alertSwipeNavigation;
}
export const isArrayEqual = (x, y) => isEmpty(xorWith(x, y, isEqual));
